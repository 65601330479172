import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { lazy, Suspense } from "react"
import "./App.css"
import Loading from "./components/Loading"
const Listing = lazy(() => import("./components/Listing"))
const Header = lazy(() => import("./components/Header"))
const Footer = lazy(() => import("./components/Footer"))
const Blog = lazy(() => import("./components/Blog"))
const About = lazy(() => import("./components/About"))
const Post = lazy(() => import("./components/Post"))
const Error = lazy(() => import("./components/Error"))
const ScrollToTop = lazy(() => import("./components/ScrollToTop"))

function App() {
  function scrollButton(e) {
    e.preventDefault()
    const scroll = document.documentElement.scrollTop
    if (scroll > 200) {
      document.querySelector("#scroll-top").style.display = "flex"
    }
    else if (scroll <= 200) {
      document.querySelector("#scroll-top").style.display = "none"
    }
  }

  function scrollUp(e) {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
  }

  window.addEventListener("scroll", scrollButton)

  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <main>
          <div className="ad-space"></div>
          <ScrollToTop />
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" exact element={<Listing />} />
              <Route path="/blog" exact element={<Blog />} />
              <Route path="/about" exact element={<About />} />
              <Route path="blog/:id" element={<Post />} />
              <Route path="*" element={<Navigate to="/error" />} />
              <Route path="/error" element={<Error />} />
            </Routes>
          </Suspense>
          <div className="ad-space"></div>
        </main>
        <Footer />
        <div id="scroll-top" onClick={scrollUp}>
          <span>
            &#8593;
          </span>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
