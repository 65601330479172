import "../styles/Loading.css"

function Loading() {
  return (
    <div className="loading-div">
      <div className="loading">

      </div>
    </div>
  )
}

export default Loading